* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'IBM Plex Sans', sans-serif;
    color: #000;
    background-color: #f5f5f5;
    margin: 0;
}

header {
    position: relative;
    background-color: #ffffff;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    max-height: 50px;
    margin-right: 10px;
}

.main-nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 768px) {
    .logo img {
        max-height: 40px;
    }
    .footer-nav ul {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .footer-nav li {
        margin-right: 20px;
        margin-bottom: 10px;
    }

    /*body {
        background-image: url('/images/backgroundImage01.png');
        background-size: cover;
        background-position: center center;
    }*/
}

nav ul {
    display: flex;
}

nav li {
    margin-left: 20px;
}

nav a {
    color: black;
    text-decoration: none;
}

nav a:hover {
    color: #666;
}

.homepage-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
    padding-top: 5rem;
}

.homepage-hero h1 {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.homepage-hero p {
    font-size: 1.5rem;
}

.bio, .services, .contact, .legal-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 1rem;
    line-height: 1.6;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 2rem;
}

.bio h2, .services h2, .contact h2, .blog-posts h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
    padding-top: 2rem;
}

.bio p, .services p, .contact p .blog-posts p {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    text-align: justify;
}

.services ul {
    list-style-type: none;
    padding: 0;
}

.services li {
    margin-bottom: 2rem;
}

.services h3 {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.services li ul {
    list-style-type: disc;
    padding-left: 2rem;
    margin-bottom: 0.5rem;
}

.contact form {
    display: flex;
    flex-direction: column;
}

.contact label {
    font-size: 1.1rem;
    margin-bottom: 0.25rem;
}

.contact input,
.contact textarea {
    font-size: 1rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact button {
    font-size: 1.1rem;
    padding: 0.5rem 1rem;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: flex-start;
}

.contact button:hover {
    background-color: #333;
}

.error-message {
    font-size: 1.1rem;
    color: #f00;
    margin-top: 1rem;
}

.required-text {
    font-size: 0.8rem;
    color: red;
}

.optional-text {
    font-size: 0.8rem;
}

/* Footer Styles */
footer {
  background-color: #f8f9fa;
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e9ecef;
  font-size: 0.9rem;
}

footer a {
  color: #343a40;
  text-decoration: none;
  margin: 0 0.5rem;
}

footer a:hover {
  text-decoration: underline;
}

footer p {
  margin-bottom: 0.5rem;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.footer-text {
    font-size: 0.69rem;
}

/* Terms and Privacy Styles */
.legal-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
  line-height: 1.6;
}

.legal-page h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
  padding-top: 2rem;
}

.legal-page h3 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.legal-page p {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  text-align: justify;
}

.legal-page ul {
  list-style-type: disc;
  padding-left: 2rem;
  margin-bottom: 1rem;
}

.legal-page li {
  margin-bottom: 0.5rem;
}

.privacy-email a {
    color: black;
    text-decoration: none;
}

.privacy-email a:hover {
    color: #666;
    text-decoration: underline;
}

